import AboutMe from "../components/AboutMe/AboutMe";
import { HeroHeader } from "../components/HeroHeader";
import PortfolioOverview from "../components/PortfolioOverview";

function Home() {
    return (
        <>
            <HeroHeader 
                subtitle="Hallo daar!"
            />
            <AboutMe />
            <PortfolioOverview />
        </>
    );
}

export default Home;