import portraitImage from '../assets/images/portrait-transparent.webp';
import SectionTitle from './SectionTitle';
import { TypeAnimation } from 'react-type-animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Button from './Button';

export default function AboutMeHeader() {
    return (
        <section className='bg-about-me-header bg-cover bg-center'>
            <div className="pt-28 flex flex-col lg:flex-row gap-11 justify-center items-end container mx-auto px-3">
                {/* Image */}
                <div className="flex-1 flex order-2 lg:order-1">
                    <img src={portraitImage} alt="Marwin" className="w-full max-w-3xl object-contain" />
                </div>

                {/* Content */}
                <div className="flex-1 max-w-2xl flex gap-6 flex-col lg:mb-14 order-1 lg:order-2">
                    <SectionTitle
                        subTitle="Hallo daar!"
                        title={(
                            <>
                                Ik ben <span className="text-primary">Marwin Doddema</span>
                            </>
                        )}
                        thirdTitle={
                            <TypeAnimation
                                sequence={[
                                    "UI/UX Designer",
                                    2000,
                                    'Front-End Developer',
                                    2000,
                                    'Graphic Designer',
                                    2000,
                                    'Online Marketeer',
                                    2000,
                                ]}
                                wrapper="span"
                                speed={50}
                                repeat={Infinity}
                            />
                        }
                        margin={false}
                        hr={true}
                        align="left"
                        isH1={true}
                    />
                    <div>
                        <p>
                            Geboren in Delfzijl en nu wonend in Groningen met mijn vriendin Myrine, heb ik <strong>zeven jaar ervaring in design en development</strong>, met de laatste vier jaar gericht op <strong>websites, apps en online marketing</strong>. Na mijn opleiding <strong>Communicatie en Multimedia Design</strong> aan de Hanzehogeschool heb ik mijn passie voor <strong>design en techniek</strong> verder ontwikkeld. Buiten werk train ik voor mijn eerste <strong>marathon</strong> en zoek ik avontuur in <strong>klimmen</strong>. <strong>Creatief en gedreven</strong>, zet ik me altijd in om iets moois neer te zetten.
                        </p>
                    </div>
                    <div className='flex gap-3 flex-col items-start lg:flex-row'>
                        <Button className='flex gap-2 items-center' variant='primary'><FontAwesomeIcon className='w-5 h-5' icon={faWhatsapp}/>Stuur een appje!</Button>
                        <Button className='flex gap-2 items-center' variant='color'><FontAwesomeIcon className='w-5 h-5' icon={faLinkedin}/>LinkedIn</Button>
                        <Button variant='secondary'>Neem contact op</Button>
                    </div>
                </div>
            </div>
        </section>
    );
};
