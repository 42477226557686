import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTopOnRouteChange() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [pathname]); // scroll to top whenever the route changes

    return null; // This component doesn't render anything visible
}