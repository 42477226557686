import { Tags } from "../Tags";

export default function PortfolioHeader({ title = 'Title', tags }) {
    return (
        <header className="bg-hero bg-cover pt-44 pb-8">
            <div className="container px-3 mx-auto">
                <h1 className="text-white text-6xl">{title}</h1>
                {tags?.length > 0 && (
                    <Tags className='mt-3' tags={tags} />
                )}
            </div>
        </header>
    );
};