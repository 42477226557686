import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import useMediaQuery from '../custom_hooks/useMediaQuery';

function NavItem({ to, children, className, onClick, exact = true }) {
    return (
        <NavLink 
            to={to}
            end={exact}
            className={({ isActive }) => isActive ? `${className} text-primary font-semibold` : `${className}`}
            onClick={onClick}
        >
            {children}
        </NavLink>
    );
}

export default function Nav() {
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    // Function to close menu
    const closeMenu = () => setMenuOpen(false);

    if (isDesktop) {
        // Desktop Navbar
        return (
            <nav className='p-3 max-w-screen-2xl fixed left-0 right-0 mx-auto z-10'>
                <div className='bg-white shadow-card rounded-2xl p-3 flex justify-between'>
                    <div className='flex items-center gap-6'>
                        <div className='pl-2'>
                            <NavItem to="/">
                                <Logo width='40px' />
                            </NavItem>
                        </div>
                        <ul className='text-base font-nav flex gap-4'>
                            <li>
                                <NavItem to="/">Home</NavItem>
                            </li>
                            <li>
                                <NavItem to="/over-mij">Over Mij</NavItem>
                            </li>
                            <li>
                                <NavItem to="/portfolio" exact={false}>Portfolio</NavItem>
                            </li>
                            <li>
                                <NavItem to="/blog" exact={false}>Blog</NavItem>
                            </li>
                            <li>
                                <NavItem to="/contact">Contact</NavItem>
                            </li>
                        </ul>
                    </div>
                    <div className='flex gap-3'>
                        <Button className='flex gap-2 items-center' variant='primary'>
                            <FontAwesomeIcon className='w-5 h-5' icon={faWhatsapp} />
                            Stuur een appje!
                        </Button>
                        <Button variant='secondary'>Neem contact op</Button>
                    </div>
                </div>
            </nav>
        );
    } else {
        // Mobile Navbar
        return (
            <nav className='p-3 max-w-screen-2xl fixed left-0 right-0 mx-auto z-10'>
                <div className='bg-white shadow-card rounded-2xl p-3 flex flex-col gap-3'>
                    <div className='flex justify-between'>
                        <div className='flex items-center gap-6'>
                            <div className='pl-2'>
                                <NavItem to="/">
                                    <Logo width='40px' />
                                </NavItem>
                            </div>
                        </div>
                        <button className="h-12 flex items-center px-2" onClick={toggleMenu}>
                            <FontAwesomeIcon className='w-6 h-6' icon={menuOpen ? faTimes : faBars} />
                        </button>
                    </div>
                    {menuOpen && (
                        <>
                            <div>
                                <ul className='text-base font-nav flex flex-col gap-2'>
                                    <NavItem to="/" className="transition-all p-3 rounded-lg hover:bg-light-background flex justify-between items-center" onClick={closeMenu}>
                                        <li className='flex justify-between items-center w-full'>
                                            Home
                                            <FontAwesomeIcon icon={faChevronRight} className='text-grey-text' />
                                        </li>
                                    </NavItem>
                                    <NavItem to="/over-mij" className="transition-all p-3 rounded-lg hover:bg-light-background flex justify-between items-center" onClick={closeMenu}>
                                        <li className='flex justify-between items-center w-full'>
                                            Over Mij
                                            <FontAwesomeIcon icon={faChevronRight} className='text-grey-text' />
                                        </li>
                                    </NavItem>
                                    <NavItem to="/portfolio" className="transition-all p-3 rounded-lg hover:bg-light-background flex justify-between items-center" onClick={closeMenu} exact={false}>
                                        <li className='flex justify-between items-center w-full'>
                                            Portfolio
                                            <FontAwesomeIcon icon={faChevronRight} className='text-grey-text' />
                                        </li>
                                    </NavItem>
                                    <NavItem to="/blog" className="transition-all p-3 rounded-lg hover:bg-light-background flex justify-between items-center" onClick={closeMenu} exact={false}>
                                        <li className='flex justify-between items-center w-full'>
                                            Blog
                                            <FontAwesomeIcon icon={faChevronRight} className='text-grey-text' />
                                        </li>
                                    </NavItem>
                                    <NavItem to="/contact" className="transition-all p-3 rounded-lg hover:bg-light-background flex justify-between items-center" onClick={closeMenu}>
                                        <li className='flex justify-between items-center w-full'>
                                            Contact
                                            <FontAwesomeIcon icon={faChevronRight} className='text-grey-text' />
                                        </li>
                                    </NavItem>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-3 items-start p-3'>
                                <Button className='flex gap-2 items-center' variant='primary'>
                                    <FontAwesomeIcon className='w-5 h-5' icon={faWhatsapp} />
                                    Stuur een appje!
                                </Button>
                                <Button variant='secondary'>Neem contact op</Button>
                            </div>
                            <div className='px-3 pb-3'>
                                <button>
                                    <FontAwesomeIcon icon={faLinkedin} className='text-primary h-6 w-6'/>
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </nav>
        );
    }
}
