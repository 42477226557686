import { HorizontalRule } from "./HorizontalRule";
import { TypeAnimation } from 'react-type-animation';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export function HeroHeader({ subtitle }) {
    return (
      <header className="bg-hero bg-cover">
        <div className="container px-3 mx-auto pt-48 py-36">
            <div className="flex gap-12 flex-col">
                <div className="flex max-w-2xl gap-3 flex-col">
                    <h3 className="text-white text-sm uppercase">{subtitle}</h3>
                    <h1 className="text-white text-6xl leading-h1 min-h-60">
                        <TypeAnimation
                            sequence={[
                                "Ik ben Marwin, een creatieve UI/UX designer",
                                2000,
                                'Ik ben Marwin, een creatieve Front-End Developer',
                                2000,
                                'Ik ben Marwin, een creatieve Graphic Designer',
                                2000,
                                'Ik ben Marwin, een creatieve Online Marketeer',
                                2000,
                            ]}
                            wrapper="span"
                            speed={50}
                            repeat={Infinity}
                            />
                    </h1>
                    <HorizontalRule />
                </div>
                <div className='flex gap-3'>
                    <Button variant="color">Leer mij beter kennen!</Button>
                    <Button className='flex gap-2 items-center' variant='secondary-white'><FontAwesomeIcon className='w-5 h-5' icon={faWhatsapp}/>Stuur een appje!</Button>
                </div>
            </div>
        </div>
      </header>
    );
}