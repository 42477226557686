const tagColors = {
    "UI/UX Design": "bg-blue-600 bg-opacity-30 text-blue-600",
    "Graphic Design": "bg-pink-400 bg-opacity-30 text-pink-400",
    "Drone beelden": "bg-emerald-400 bg-opacity-30 text-emerald-400",
    "Logo ontwerp": "bg-orange-400 bg-opacity-30 text-orange-400",
    "Video editing": "bg-fuchsia-800 bg-opacity-30 text-fuchsia-800",
    "Drukwerk": "bg-amber-500 bg-opacity-30 text-amber-500",
    "HTML & CSS": "bg-red-600 bg-opacity-30 text-red-600",
    "Javascript": "bg-yellow-600 bg-opacity-30 text-yellow-600",
    "Wordpress": "bg-violet-700 bg-opacity-30 text-violet-500",
    "SEO": "bg-green-600 bg-opacity-30 text-green-600",
    "Online Marketing": "bg-cyan-600 bg-opacity-30 text-cyan-600",
};

export default tagColors;