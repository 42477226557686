import SectionTitle from "./SectionTitle";

export default function ImageRow({ title, paragraph, reverse, imgSrc }) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center">
            <div className={`${reverse ? 'order-2' : 'order-1'}`}>
                <SectionTitle
                    title={title}
                    hr={true}
                    align="left"
                    margin={false}
                />
                <p className="pt-5">{paragraph}</p>
            </div>
            <div className={`${reverse ? 'md:order-1' : 'md:order-2'}`}>
                <img className='rounded-3xl shadow-card' src={imgSrc} alt={title} />
            </div>
        </div>
    );
};