import Nav from './components/Nav';
import AppRoutes from './components/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer';
import BottomCTA from './components/BottomCTA';
import ScrollToTopOnRouteChange from './components/ScrollToTopOnRouteChange';

function App() {
  return (
    <Router>
        <ScrollToTopOnRouteChange />
        <Nav />
        <AppRoutes />
        <BottomCTA
          title='Heeft u een vraag of wilt u een afspraak maken?'
        />
        <Footer />
    </Router>
);
}

export default App;
