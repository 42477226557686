import TitleCard from "./TitleCard";
import { faCode, faPalette } from "@fortawesome/free-solid-svg-icons";
import { Tags } from "../Tags";

export default function AboutMeSkills() {
    return (
        <div className="container px-3 mx-auto">
            <div className="lg:flex grid sm:grid-cols-1 md:grid-cols-2 lg:justify-between bg-about-me bg-contain bg-bottom bg-no-repeat lg:pt-12 pb-52 gap-5 md:pb-72 lg:pb-24">
                <TitleCard
                    icon={faPalette}
                    className="lg:w-1/3"
                    iconColor='text-purple'
                    title='Creatief ontwerper'
                    children={
                        <>
                            <p>Als <strong>Creatief Ontwerper</strong> focus ik me op visuele esthetiek en gebruiksvriendelijkheid. Mijn vaardigheden in <strong>UI/UX design</strong> en <strong>graphic design</strong> zorgen voor impactvolle en functionele ontwerpen die een blijvende indruk maken.</p>
                            <div>
                                <Tags tags={["Graphic Design", "Drone beelden", "UI/UX Design", "Logo ontwerp", "Video editing", "Drukwerk"]} />
                            </div>
                        </>
                    }
                />
                <TitleCard
                    icon={faCode}
                    className="lg:w-1/3"
                    iconColor='text-mint'
                    title='Technisch specialist'
                    children={
                        <>
                            <p>Als <strong>Technisch Specialist</strong> bouw ik robuuste en responsieve weboplossingen met <strong>HTML, CSS, en React</strong>. Mijn technische expertise zorgt voor een naadloze uitvoering van creatieve ontwerpen, met oog voor detail en functionaliteit.</p>
                            <div>
                                <Tags tags={["HTML & CSS", "Javascript", "Wordpress", "SEO", "Online Marketing"]} />
                            </div>
                        </>
                    }
                />
            </div>
        </div>
    );
}