import clsx from 'clsx';
import { HorizontalRule } from "./HorizontalRule";

export default function SectionTitle({ title, subTitle, hr, align = "center", thirdTitle, margin = true, isH1 = false }) {
    // Flexbox alignment classes for the parent container
    const flexAlignmentClasses = clsx({
        'justify-start': align === 'left',
        'justify-center': align === 'center',
        'justify-end': align === 'right'
    });

    // Text alignment classes for the inner content
    const textAlignmentClasses = clsx({
        'text-left': align === 'left',
        'text-center': align === 'center',
        'text-right': align === 'right'
    });

    // Align items vertically in the flex container
    const itemsAlignmentClasses = clsx({
        'items-start': align === 'left',
        'items-center': align === 'center',
        'items-end': align === 'right'
    });

    // Conditionally render h1 or h2 based on isH1 prop
    const TitleTag = isH1 ? 'h1' : 'h2';

    return (
        <div className={clsx('container mx-auto flex', flexAlignmentClasses, { 'mb-12': margin })}>
            <div className={`max-w-3xl flex flex-col gap-3 font-bold ${textAlignmentClasses} ${itemsAlignmentClasses}`}>
                {subTitle && <h3 className="text-lg">{subTitle}</h3>}
                <TitleTag className="text-5xl leading-snug">{title}</TitleTag>
                {thirdTitle && <h4 className="text-3xl">{thirdTitle}</h4>}
                {hr && <HorizontalRule />}
            </div>
        </div>
    );
}