import Container from "../components/Container";
import BlogGrid from "../components/BlogGrid";
import SectionTitle from "../components/SectionTitle";

export default function Portfolio() {
    return (
        <Container top={true}>
            <SectionTitle
                subTitle='Portfolio'
                title='Neem een kijkje in mijn creaties'
                hr={true}
                align="center"
                margin={false}
            />
            <BlogGrid contentType='portfolio'/>
        </Container>
    )
}