import { useRoutes } from 'react-router-dom';
import Home from '../pages/Home';
import OverMij from '../pages/OverMij';
import PortfolioItem from './Portfolio/PortfolioItem';
import Contact from '../pages/Contact';
import Portfolio from '../pages/Portfolio';
import Blog from '../pages/Blog';

export default function AppRoutes() {
    let routes = useRoutes([
      { path: "/", element: <Home /> },
      { path: "/over-mij", element: <OverMij /> },
      { path: "/contact", element: <Contact /> },
      { path: "/blog", element: <Blog /> },
      { path: "/blog/:slug", element: <PortfolioItem contentType='blog' /> },
      { path: "/portfolio", element: <Portfolio /> },
      { path: "/portfolio/:slug", element: <PortfolioItem contentType='portfolio' /> }
    ]);
    return routes;
  };