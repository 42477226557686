import AboutMeSkills from "./AboutMeSkills";
import SectionTitle from "../SectionTitle";

export default function AboutMe() {
    return (
        <section className="pt-24">
            <SectionTitle
                subTitle='Over mij'
                title='De creatieve alleskunner'
                hr={true}
                align="center"
            />
            <AboutMeSkills />
        </section>
    );
};