import { Link } from "react-router-dom";

export default function PortfolioAsideItems({ posts, contentType }) {
    if (posts.length < 1) {
        return null;
    }
    return (
        <div className="px-2 pt-2 pb-5 rounded-2xl shadow-card bg-white flex flex-col gap-3">
            <div className="gap-3 py-4 px-3 rounded-xl bg-black flex items-center">
                <h3 className="text-white text-xl">Kijk ook eens hiernaar!</h3>
            </div>
            <ul className="px-3">
                {posts.map((post) => (
                <li key={post.sys.id}>
                    <Link to={`/${contentType}/${post.fields.slug}`}>
                    <h1>{post.fields.title}</h1>
                    </Link>
                </li>
                ))}
            </ul>
        </div>
    );
  }
