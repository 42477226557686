import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TitleCard({ icon, iconColor, title, body, tags, children, className }) {
    return (
        <div className={`${className} px-2 pt-2 pb-5 rounded-2xl shadow-card bg-white flex flex-col gap-3`}>
            <div className="gap-3 py-4 px-3 rounded-xl bg-black flex items-center">
                <FontAwesomeIcon className={`w-6 h-6 ${iconColor}`} icon={icon} />
                <h3 className="text-white text-xl">{title}</h3>
            </div>
            <div className="px-3 flex flex-col gap-2">{children}</div>
        </div>
    );
};
