import React, { useState, useEffect } from 'react';
import client from '../contentfulClient';
import Button from './Button';
import { Link } from 'react-router-dom';
import { Tags } from './Tags';

export default function BlogGrid({ contentType }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPostsAndTags() {
      try {
        // Fetch blog posts
        const postsResponse = await client.getEntries({ content_type: contentType });
        const posts = postsResponse.items;
  
        // Get unique tag IDs from posts
        const tagIds = posts.flatMap(post => post.metadata.tags.map(tag => tag.sys.id));
        const uniqueTagIds = [...new Set(tagIds)]; // Remove duplicates
  
        // Fetch tag details using tag IDs
        const tagsResponse = await client.getTags(); // Fetch all tags
        const tags = tagsResponse.items;
  
        // Create a map of tag ID to tag name
        const tagMap = {};
        tags.forEach(tag => {
          tagMap[tag.sys.id] = tag.name;
        });
  
        // Add tag names to posts
        const postsWithTagNames = posts.map(post => ({
          ...post,
          tagNames: post.metadata.tags.map(tag => tagMap[tag.sys.id]),
        }));
  
        setPosts(postsWithTagNames); // Save posts with tag names
      } catch (error) {
        console.error(error);
      }
    }
  
    fetchPostsAndTags();
  }, []);

  // Helper function to format the date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';  // Add ellipsis if text is longer than maxLength
    }
    return text || '';  // Return the original text or empty string if it's undefined
  };

  return (
    <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>
        {posts.map((post) => (
          <div key={post.sys.id} className='rounded-2xl shadow-card bg-white p-2 pb-5 flex gap-6 flex-col'>
            {post.fields.featuredImage && (
              <img 
                src={post.fields.featuredImage.fields.file.url} 
                alt={post.fields.featuredImage.fields.title}
                className='rounded-xl'
              />
            )}
            <div className='px-3 flex flex-col gap-3'>
                <Link to={`/${contentType}/${post.fields.slug}`}>
                  <h2 className='text-2xl'>{post.fields.title}</h2>
                </Link>
                <p>{formatDate(post.sys.createdAt)}</p>
                <p>{truncateText(post.fields.description, 168)}</p>
                {post.tagNames?.length > 0 && (
                    <Tags tags={post.tagNames} />
                )}
                <Link to={`/${contentType}/${post.fields.slug}`}>
                  <Button variant='secondary' className='w-fit'>
                    Bekijk project
                  </Button>
                </Link>
            </div>
          </div>
        ))}
    </div>
  );
}