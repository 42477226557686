import tagColors from "../data/tagColors";

export function Tags({ tags, className }) {
    return (
        <div className={`${className} flex flex-wrap gap-2`}>
            {tags.map((tag, index) => (
                <span 
                    key={index} 
                    className={`text-sm font-tag px-2 py-1 rounded-md ${tagColors[tag] || 'bg-gray-200 text-gray-800'}`}
                >
                    {tag}
                </span>
            ))}
        </div>
    );
}