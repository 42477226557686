import { TypeAnimation } from "react-type-animation";
import ContactPortrait from "../components/ContactPortrait";
import Container from "../components/Container";
import FlexibleGrid from "../components/FlexibleGrid";
import SectionTitle from "../components/SectionTitle";

export default function Contact() {
    return (
        <Container className='bg-light-background' top={true}>
            <SectionTitle
                subTitle='Contact'
                title='Wat leuk dat je geïnteresseerd bent!'
                hr={true}
                margin={false}
            />
            <FlexibleGrid
                leftContent={
                    <>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vestibulum, neque in scelerisque vehicula, magna metus pellentesque mi, vitae iaculis lectus justo id ante. Nullam feugiat mauris in elit tempor suscipit. Nullam condimentum ultricies sapien, non viverra orci euismod mollis. In sapien dui, eleifend nec arcu vitae, maximus euismod felis. Praesent laoreet mollis neque, vitae euismod ante venenatis et. Integer ut malesuada nibh, sit amet placerat justo. Nullam eget hendrerit urna, eu elementum nisl. Mauris aliquet eros ac velit feugiat molestie.

Nunc fringilla ornare augue non egestas. Sed sagittis ligula eget urna suscipit interdum. Aliquam nec leo nulla. Duis tempus, metus in semper varius, neque velit laoreet tellus, nec lobortis est est ac lorem. Nam mollis auctor mauris, ut interdum sapien interdum dignissim. Mauris eros libero, ultrices semper mattis eu, porta sit amet ipsum. Nullam porta elementum purus. Integer sed ornare mi, a dapibus ante. Donec viverra tortor vel sollicitudin rhoncus.
                        </p>
                    </>  
                }
                rightContent={
                    <>
                        <ContactPortrait
                            name='Marwin Doddema'
                            title={
                                <TypeAnimation
                                    sequence={[
                                        "UI/UX designer",
                                        2000,
                                        'Front-End Developer',
                                        2000,
                                        'Graphic Designer',
                                        2000,
                                        'Online Marketeer',
                                        2000,
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    repeat={Infinity}
                                />
                            }
                            backgroundImage='bg-contact'
                        />
                    </>
                }
                align="center"
            />
        </Container>
    );
}