import Button from "./Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from "./Logo";

export default function BottomCTA({ title = 'Title',  }) {
    return (
        <section className="bg-primary flex items-center flex-col py-24 gap-5">
            <Logo primaryFill="#262626" secondaryFill="#ffffff" width={100}/>
            <h2 className="text-5xl leading-snug text-white max-w-3xl text-center">{title}</h2>
            <div className='flex gap-3'>
                <Button className='flex gap-2 items-center' variant='primary'><FontAwesomeIcon className='w-5 h-5' icon={faWhatsapp}/>Stuur een appje!</Button>
                <Button variant='secondary-white'>Neem contact op</Button>
            </div>
        </section>
    )
}