import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import client from '../contentfulClient';
import { faBuilding, faCalendarAlt, faMapPin } from '@fortawesome/free-solid-svg-icons';

export default function AboutMeTimeline({ tag }) {
    const [timelineEntries, setTimelineEntries] = useState([]);

    useEffect(() => {
        const fetchTimelineEntries = async () => {
            try {
                const entries = await client.getEntries({
                    content_type: 'timeline',
                    'metadata.tags.sys.id[in]': tag,   // Filter by the passed tag
                });

                // Sort the entries by endDate in descending order
                const sortedEntries = entries.items.sort((a, b) => {
                    const dateA = new Date(a.fields.endDate); // Convert endDate to Date object
                    const dateB = new Date(b.fields.endDate);
                    return dateB - dateA; // Descending order
                });

                setTimelineEntries(sortedEntries); // Store the sorted entries in state
            } catch (error) {
                console.error("Error fetching timeline entries:", error);
            }
        };

        fetchTimelineEntries();
    }, [tag]); // Re-run the fetch if the tag prop changes

    return (
        <div className="pt-2 flex flex-col gap-5">
            {timelineEntries.length > 0 ? (
                <div className='flex flex-col gap-8'>
                    {timelineEntries.map((entry) => (
                        <div key={entry.sys.id} className='flex gap-3'>
                            <img 
                                src={entry.fields.companyImage.fields.file.url} 
                                alt={entry.fields.companyImage.fields.title}
                                className='rounded-xl w-20 h-20'
                            />
                            <div className='flex flex-col gap-1'>
                                <h3 className='text-xl'>{entry.fields.title}</h3>
                                <ul className='text-grey-text flex gap-4'>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                                        <span className='font-heading'>{entry.fields.company}</span>
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faMapPin} className="mr-2" />
                                        <span className='font-heading'>{entry.fields.location}</span>
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                                        <span className='font-heading'>{entry.fields.date}</span>
                                    </li>
                                </ul>
                                <p>{entry.fields.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No timeline entries found for tag "{tag}".</p>
            )}
        </div>
    );
}
