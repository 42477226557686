import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';  // Import Skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';  // Import Skeleton CSS
import client from '../../contentfulClient';
import PortfolioHeader from './PortfolioHeader';
import PortfolioAsideItems from './PortfolioAsideItems';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function PortfolioItem({ contentType }) {
  const { slug } = useParams();  // Get slug from URL
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]); // State for related posts
  const richTextOptions = {
    renderNode: {
      'embedded-asset-block': (node) => {
        const { file, title } = node.data.target.fields;
        return <img src={file.url} alt={title} className="my-6" />;
      },
      'paragraph': (node, children) => <p className="my-4">{children}</p>,  // Apply margin to paragraphs
      'heading-1': (node, children) => <h1 className="text-6xl my-8">{children}</h1>,
      'heading-2': (node, children) => <h2 className="text-4xl my-6">{children}</h2>,
      'heading-3': (node, children) => <h3 className="text-3xl my-5">{children}</h3>,
      'heading-4': (node, children) => <h4 className="text-2xl my-4">{children}</h4>,
      'heading-5': (node, children) => <h5 className="text-xl my-3">{children}</h5>,
    }
  };

  useEffect(() => {
    async function fetchPostAndTags() {
      try {
        const response = await client.getEntries({
          content_type: contentType,
          'fields.slug': slug  // Fetch post by slug
        });
        const post = response.items[0];  // Assuming slug is unique

        // Get unique tag IDs from post
        const tagIds = post.metadata.tags.map(tag => tag.sys.id);
        
        // Fetch all tags and create a tagMap
        const tagsResponse = await client.getTags();
        const tags = tagsResponse.items;
        const tagMap = {};
        tags.forEach(tag => {
          tagMap[tag.sys.id] = tag.name;
        });

        // Add tag names to the post
        const postWithTagNames = {
          ...post,
          tagNames: tagIds.map(tagId => tagMap[tagId])
        };

        // Fetch related posts
        const relatedPostsResponse = await client.getEntries({
          content_type: contentType,
          'fields.slug[ne]': slug,  // Fetch posts excluding the current one
        });
        
        const relatedPosts = relatedPostsResponse.items;

        setPost(postWithTagNames);  // Save post with tag names
        setRelatedPosts(relatedPosts);  // Save related posts
      } catch (error) {
        console.error(error);
      }
    }

    fetchPostAndTags();
  }, [slug]);

  if (!post) {
    return (
      <>
        <PortfolioHeader
          title={<Skeleton width={300} />}
          tags={<Skeleton width={100} count={3} />}
        />
        <section className='container mx-auto px-2 my-24'>
          <div className='grid grid-cols-3 gap-5'>
            <article className='col-span-2'>
              <Skeleton height={400} />
              <p><Skeleton count={5} /></p>
            </article>
            <aside className='col-span-1'>
              <div>
                <ul>
                  <Skeleton count={5} height={40} />
                </ul>
              </div>
            </aside>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <PortfolioHeader
        title={post.fields.title}
        tags={post.tagNames}
      />
      <section className='container mx-auto px-2 my-24'>
        <div className='grid grid-cols-3 gap-5'>
          <article className='col-span-2'>
            <div>
              {documentToReactComponents(post.fields.body, richTextOptions)}
            </div>
          </article>
          <aside className='col-span-1'>
            <div>
              <ul>
                <PortfolioAsideItems posts={relatedPosts} contentType={contentType} />
              </ul>
            </div>
          </aside>
        </div>
      </section>
    </>
  );
}