export default function Logo({ width = 64, primaryFill = '#4370ff', secondaryFill = '#262626' }) {
    return (
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.41 64.92" width={width}>
            <g id="Layer_1-2" data-name="Layer_1">
                <g>
                    <g>
                        <polygon
                            style={{ fill: primaryFill }}
                            points="15.36 13.85 18.62 20.35 21.87 13.85 15.36 13.85"
                        />
                        <path
                            style={{ fill: secondaryFill }}
                            d="M46.73,51.08h-18.55L46.79,13.85h12.4c3.44,0,6.22,2.79,6.22,6.22v12.33c0,10.32-8.36,18.68-18.68,18.68Z"
                        />
                        <polygon
                            style={{ fill: secondaryFill }}
                            points="25.69 13.85 18.62 27.98 11.55 13.85 0 13.85 0 51.08 11.55 51.08 11.55 36.94 18.62 51.08 37.23 13.85 25.69 13.85"
                        />
                    </g>
                    <path
                        style={{ fill: primaryFill }}
                        d="M17.17,64.92c-.23,0-.45-.05-.67-.16-.74-.37-1.04-1.27-.67-2.01L46.79.83c.37-.74,1.27-1.04,2.01-.67.74.37,1.04,1.27.67,2.01l-30.96,61.92c-.26.53-.79.83-1.34.83Z"
                    />
                </g>
            </g>
        </svg>
    );
};