import AboutMeHeader from "../components/AboutMeHeader";
import Container from "../components/Container";
import ImageRow from "../components/ImageRow";
import workMarwin from "../assets/images/work-marwin.webp"
import hikeMarwin from "../assets/images/hike-marwin.webp"
import SectionTitle from "../components/SectionTitle";
import FlexibleGrid from "../components/FlexibleGrid";
import AboutMeTimeline from "../components/AboutMeTimeline";
import { faBuilding, faCode } from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import TitleCard from "../components/AboutMe/TitleCard";
import { Tags } from "../components/Tags";

export default function OverMij() {
    return (
        <>
            <AboutMeHeader />
            <Container>
                <ImageRow
                    imgSrc={hikeMarwin}
                    title='Natuurlijk avontuurlijk!'
                    paragraph='Avontuur stroomt door mijn aderen! Ik kan geen genoeg krijgen van het ontdekken van nieuwe plekken. Of het nu gaat om het hiken en wildkamperen met vrienden in de adembenemende Dolomieten, de ruige schoonheid van Noorwegen of de majestueuze bergen van Zwitserland, ik ben altijd in voor een nieuwe uitdaging. Ook zijn rotsklimmen en boulderen voor mij de perfecte manier om mijn grenzen te verleggen en die adrenaline door mijn lijf te laten gieren. Elk avontuur brengt een nieuw verhaal, en ik ben er klaar voor!'
                />
                <ImageRow
                    reverse={true}
                    imgSrc={workMarwin}
                    title='Waar Sfeer en Design samenkomen'
                    paragraph='Als je me zou omschrijven, dan zou je waarschijnlijk zeggen dat ik altijd vrolijk ben en de sfeer erin breng. Ik hou ervan om gezelligheid te creëren, waar ik ook ben. Voor mij draait het om verbinding maken met mensen en samen genieten van de kleine momenten. Daarnaast krijg ik ontzettend veel energie van het creëren van mooie dingen. Of het nu gaat om een website, een ontwerp of iets anders, ik wil dat wat ik maak mensen blij maakt en hen inspireert om het met plezier te gebruiken. Die glimlach op iemands gezicht als ze iets nieuws ontdekken dat werkt en er goed uitziet – dat is precies waarom ik doe wat ik doe!'
                />
            </Container>
            <Container className='bg-light-background'>
                <SectionTitle
                    title='Alles even op een rijtje'
                    subTitle='Over mij'
                    hr={true}
                    margin={false}
                />
                <FlexibleGrid
                    layout="2-1"
                    leftContent={
                        <>
                            <TitleCard title='Werkervaring' icon={faBuilding} iconColor='text-mint'>
                                <AboutMeTimeline
                                    tag='company'
                                />
                            </TitleCard>
                            <TitleCard title='Opleidingen' icon={faGraduationCap} iconColor='text-purple'>
                                <AboutMeTimeline
                                    tag='study'
                                />
                            </TitleCard>
                        </>
                    } 
                    rightContent={
                        <TitleCard title='Expertise' icon={faCode} iconColor='text-red-500'>
                            <>
                                <p>Als Technisch Specialist bouw ik robuuste en responsieve weboplossingen met HTML, CSS, en React. Mijn technische expertise zorgt voor een naadloze uitvoering van creatieve ontwerpen, met oog voor detail en functionaliteit.</p>
                                <Tags tags={["Graphic Design", "Drone beelden", "UI/UX Design", "Logo ontwerp", "Video editing", "Drukwerk", "HTML & CSS", "Javascript", "Wordpress", "SEO", "Online Marketing"]}></Tags>
                            </>
                        </TitleCard>
                    }
                />
            </Container>
        </>
    );
}