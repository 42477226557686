import clsx from "clsx";

const variantClasses = {
    primary: 'bg-black text-white hover:bg-black-darken',
    secondary: 'bg-transparent border-black border-solid border text-black hover:bg-transparent-black',
    'secondary-white': 'bg-transparent border-white border-solid border text-white hover:bg-transparent-black',
    color: 'bg-primary text-white hover:bg-primary-darken',
};

export default function Button({ variant = 'primary', className, children, ...props }) {
    const baseClasses = 'py-3 px-5 font-button rounded-lg transition-all font-medium hover:scale-105';
    const classes = clsx(
        baseClasses,
        variantClasses[variant],
        className
    );
    return (
        <button className={classes} {...props}>
            {children}
        </button>
    );
};