import PortfolioGrid from "./BlogGrid";
import SectionTitle from "./SectionTitle";

export default function PortfolioOverview() {
    return (
        <section className="py-24 bg-light-background">
            <SectionTitle
                subTitle='Portfolio'
                title='Neem een kijkje in mijn creaties'
                hr={true}
                align="center"
            />
            <div className="container px-3 mx-auto">
                <PortfolioGrid contentType='portfolio' />
            </div>
        </section>
    );
};